/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { graphql, HeadProps, PageProps } from "gatsby"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"
import PageDataBodyPageIntro from "../slices/PageDataBodyPageIntro"
import { BoundedBox } from "../components/BoundedBox"
import { Text } from "../components/Text"
import { Link } from "gatsby"
import { SitemapPageQuery } from "../graphql.gen"

const SitemapSection = (props: {
	label: string
	links: Array<{ href: string; label: string }>
}) => {
	return (
		<nav
			className="grid md:grid-cols-2 lg:grid-cols-4 place-items-start gap-x-8 xl:gap-x-12 lg:gap-y-4"
			aria-label="Pages Sitemap"
		>
			<Text
				asChild
				variant="heading3"
				className="border-l-4 border-red pl-4 py-1 mb-4 md:col-span-full md:mb-8 lg:mb-4"
			>
				<h2>{props.label}</h2>
			</Text>

			{props.links.map((l) => (
				<Text
					key={l.href}
					asChild
					variant="sitemapLink"
					className="hover:text-red transition py-3 lg:py-4"
				>
					<Link to={l.href}>{l.label}</Link>
				</Text>
			))}
		</nav>
	)
}

const SitemapPage = ({ data }: PageProps<SitemapPageQuery>) => {
	const {
		allPrismicBlogPost,
		allPrismicPage,
		allPrismicPerson,
		allPrismicPracticeArea,
	} = data

	return (
		<>
			<PageDataBodyPageIntro heading="Sitemap" />
			<BoundedBox>
				<div className="space-y-20">
					<SitemapSection
						label="Pages"
						links={allPrismicPage.nodes
							.filter((n) => n.data.title?.text && n.url)
							.map((n) => ({
								href: n.url!,
								label: n.data.title!.text!,
							}))}
					/>

					<SitemapSection
						label="Practice Areas"
						links={allPrismicPracticeArea.nodes
							.filter((n) => n.data.title?.text && n.url)
							.map((n) => ({
								href: n.url!,
								label: n.data.title!.text!,
							}))}
					/>

					<SitemapSection
						label="People"
						links={allPrismicPerson.nodes
							.filter((n) => n.data.name?.text && n.url)
							.map((n) => ({
								href: n.url!,
								label: n.data.name!.text!,
							}))}
					/>

					<SitemapSection
						label="Blog"
						links={allPrismicBlogPost.nodes
							.filter((n) => n.data.title?.text && n.url)
							.map((n) => ({
								href: n.url!,
								label: n.data.title!.text!,
							}))}
					/>
				</div>
			</BoundedBox>
		</>
	)
}

export const Head = ({ location }: HeadProps<SitemapPageQuery>) => {
	const settings = useSiteSettings()

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle="Sitemap"
			pathname={location.pathname}
			meta={{
				description: settings.siteDescription,
				title: "Sitemap",
			}}
			twitter={{ username: settings.twitter.username }}
			openGraph={{ cardImageUrl: settings.openGraph.cardImageUrl }}
		/>
	)
}

export default SitemapPage

export const query = graphql`
	query SitemapPage {
		allPrismicBlogPost(sort: { fields: data___title___text, order: ASC }) {
			nodes {
				data {
					title {
						text
					}
				}
				url
			}
		}
		allPrismicPage(sort: { fields: data___title___text, order: ASC }) {
			nodes {
				url
				data {
					title {
						text
					}
				}
			}
		}
		allPrismicPerson(sort: { fields: data___name___text, order: ASC }) {
			nodes {
				url
				data {
					name {
						text
					}
				}
			}
		}
		allPrismicPracticeArea(sort: { fields: data___title___text, order: ASC }) {
			nodes {
				url
				data {
					title {
						text
					}
				}
			}
		}
	}
`
